.ibf-card-shadow {
  border-radius: 3px;
  border: solid 1px #e7e2df;
}

.pointer {
  cursor: pointer;
}

// list as card
.ibf-card {
  &-width {
    max-width: 240px;
    &-favorite {
      max-width: 215px;
    }
    @media (min-width: 768px) {
      min-width: 240px;
    }

    &-carousel {
      max-width: 240px;
      @media (min-width: 768px) {
        min-width: 228px;
      }
    }
  }

  &-favorite-mobile {
    @media (max-width: 978px) and (min-width: 579px) {
      width: 40vw;
    }
    @media (max-width: 578px) {
      width: 72vw;
    }
  }

  &-width-modal {
    max-width: 230px;

    @media (min-width: 991px) {
      min-width: 230px;
    }
    @media (max-width: 990px) and (min-width: 980px) {
      max-width: 200px;
    }
    @media (max-width: 979px) {
      max-width: 200px;
    }
  }
}

@media (max-width: 576px) {
  .ibf-card-sm {
    max-width: 55vw;
  }
}

.ibf-card-sm-modal {
  @media (max-width: 576px) {
    max-width: 60vw;
  }
}

@media (min-width: 1000px) {
  .ibf-filter-list {
    width: 350px;
  }
  .ibf-filter-bar {
    width: 100%;
  }
}

.ibf-text-area {
  min-height: 50px;
}

//popovers
.custom-popover-tip {
  @media (max-width: 768px) {
    width: 330px;
    z-index: 3;
  }
  @media (min-width: 769px) {
    width: 650px;
    z-index: 2;
  }

  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    @media (min-width: 980px) {
      position: relative;
      top: 9.4em !important;
      left: 5em !important;
    }
    transform: rotate(318deg);
    width: 25px;
    height: 25px;
    bottom: 0 !important;
    background-color: #ffffce !important;
    border-color: #ffffce !important;
    box-shadow: 0 2px 1px rgba(0, 0, 0, 0.07) !important;
  }

  .ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
    left: 5em !important;
  }
}

.ibf-input-error {
  z-index: 2;

  .ant-popover-inner-content {
    background: $ibf-color-yellow;
  }

  .ant-popover-arrow {
    background: $ibf-color-yellow;
    border-right-color: $ibf-color-yellow !important;
    border-bottom-color: $ibf-color-yellow !important;
  }
}

.ibf-stems-suggestion {
  z-index: 2;

  .ant-popover-inner-content {
    background: transparent;
    padding: 0;
  }

  .ant-popover-inner {
    background-color: transparent;
  }

  .ant-popover-arrow {
    background-color: transparent;
    border-right-color: $ibf-color-yellow !important;
    border-bottom-color: $ibf-color-yellow !important;
  }

  .ant-btn {
    background: $ibf-color-yellow;
    width: 6em;
    padding: 0 5px;
  }
}

.custom-popover-favorite {
  width: 330px;

  .ant-popover-inner-content {
    background: #ffffce;
  }
  .ant-popover-arrow {
    background-color: $ibf-color-yellow !important;
    width: 25px;
    height: 25px;
    border-color: $ibf-color-yellow !important;
  }
}

.ibf-stems-validation {
  z-index: 2;
  color: red;
  width: 200px;
  .ant-popover-inner-content {
    border: whitesmoke;
  }

  .ant-popover-inner {
    background-color: white;
  }

  .ant-popover-arrow {
    background-color: white;
    border-right-color: white !important;
    border-bottom-color: white !important;
  }
}

.ibf-price-popover {
  z-index: 4;
  width: 390px;

  .ant-popover-inner-content {
    background: $ibf-color-yellow;
  }

  .ant-popover-arrow {
    border-right-color: $ibf-color-yellow !important;
    border-bottom-color: $ibf-color-yellow !important;
  }

  .ant-btn {
    border-radius: 7px;
    //width: 100%;
    //height: 45px;
    height: calc(1.5em + 0.75rem + 5px);
  }
}

.ibf-filter-pr4 {
  @media (min-width: 980px) {
    padding-right: 1.5rem !important;
  }
}

.ibf-product {
  &-list-adjustment {
    @media (max-width: 767px) {
      justify-content: space-around !important;
    }
    @media (min-width: 768px) {
      justify-content: space-between !important;
    }
    @media (min-width: 980px) {
      justify-content: space-around !important;
    }
  }

  &-input-cheapest {
    @media (max-width: 979px) {
      flex: 0 0 91.66667%;
      max-width: 91.66667%;
      margin-bottom: 0.5rem !important;
      margin-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    @media (min-width: 980px) {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
      margin-right: 0.5rem !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &-add-button {
    flex: 0 0 86.66667%;
    max-width: 86.66667%;
  }

  //form list view for mobile
  @media (max-width: 576px) {
    &-wrap {
      flex-wrap: wrap;
    }
    &-mr0 {
      margin-right: 0 !important;
      margin-bottom: 0.5em;
    }
    &-list-input {
      flex: 0 0 50%;
      max-width: 48%;
    }
  }
}

.ibf-product-detail {
  &-border {
    border: 2px solid #e83293;
    border-radius: 2px;
  }

  &-text {
    font-size: 14px;
  }
}

//product not available

.ibf-product {
  &-form-wrapper {
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 250px));
    grid-template-areas:
      'grower-select length-select .'
      'bunch-select box-select add-button';

    grid-column-gap: 10px;
    grid-row-gap: 5px;
    align-items: end;
    margin-bottom: 10px;
    &-grower-select {
      grid-area: grower-select;
    }

    &-length-select {
      grid-area: length-select;
    }

    &-bunch-select {
      grid-area: bunch-select;
    }

    &-box-select {
      grid-area: box-select;
    }

    &-add-button {
      grid-area: add-button;
    }

    @media (max-width: 1125px) and (min-width: 980px) {
      grid-template-columns: repeat(2, 250px);
      grid-template-areas:
        'grower-select length-select'
        'bunch-select box-select'
        '. add-button';
      grid-row-gap: 10px;
    }

    @media (max-width: 979px) {
      grid-template-columns: repeat(1, minmax(250px, 1fr));
      grid-template-areas:
        'grower-select'
        'length-select'
        'bunch-select'
        'box-select'
        'add-button';
      grid-row-gap: 10px;
    }
  }
  @media (max-width: 979px) and (min-width: 577px) {
    //&-form-wrapper {
    //  width: 50% !important;
    //}
    &-info-wrapper {
      width: 100% !important;
    }
  }
  @media (max-width: 576px) {
    //&-form-wrapper {
    //  width: 100% !important;
    //}
    &-form-wrapper-card {
      height: 75% !important;
    }
  }

  &-grown-text {
    @media (max-width: 576px) {
    }
    @media (min-width: 577px) {
      margin-right: 3em;
    }
  }
  &-description-wrapper {
    width: 75%;
    @media (max-width: 979px) {
      width: 100%;
    }
  }
}
