.ibf-list-product {
  &-container {
    display: flex;
    // flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  &-info {
    display: flex;
    @media (min-width: 980px) {
      flex-direction: column;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: column;
      padding: 0 0.25em;
    }
    @media (min-width: 576px) {
      //flex: 0 0 90%;
      flex-grow: 1;
      max-width: 90%;
      padding: 0.35em 1em;
    }
  }

  &-info-no-available {
    display: flex;
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
      flex-wrap: wrap;
      padding: 0 0.25em;
    }
    @media (min-width: 576px) {
      flex: 0 0 90%;
      max-width: 90%;
      justify-content: space-between;
      align-items: center;
      padding: 0.35em 2em;
    }
  }

  &-info-image {
    display: flex;
    cursor: pointer;
    justify-content: center;

    @media (max-width: 576px) {
      margin: 0.35rem 0rem;
    }
    @media (min-width: 576px) {
      margin: 0.65rem 0.65rem;
    }
  }

  &-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    @media (max-width: 376px) {
      padding-left: calc(20% + 0.9rem);
      padding-right: 1em;
      width: 100%;
    }
    @media (min-width: 377px) {
      width: 100%;
      padding-left: calc(20% - 1rem);
    }
    @media (min-width: 577px) {
      flex: 0 0 90%;
      max-width: 90%;
      padding: 0 0.5em;
    }
  }
}

.ibf-product-available {
  &-desktop {
    @media (max-width: 576px) {
      display: none !important;
    }
  }
  &-mobile {
    @media (max-width: 576px) {
      padding: 0.25em 0;
    }
    @media (min-width: 577px) {
      display: none !important;
    }
  }
}

.product-form {
  &-select-list {
    @media (max-width: 576px) {
      flex: 0 0 50%;
      max-width: 48%;
      margin-bottom: 0.5em;
    }
    @media (min-width: 577px) {
      flex: 0 0 25.33%;
      max-width: 25.33%;
      margin-right: 1.5em;
    }

    @media (min-width: 768px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin-right: 1.5em;
    }

    @media (min-width: 980px) {
      flex: 0 0 25.33%;
      max-width: 25.33%;
      margin-right: 1.5em;
    }
  }
  &-select-card {
    margin-bottom: 0.5em;
    @media (max-width: 576px) {
      flex: 0 0 40%;
      max-width: 40%;
    }
    @media (min-width: 577px) {
      flex: 0 0 41.66667%;
      max-width: 41.66667%;
    }
  }
}

.box-only-input-list {
  @media (max-width: 576px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0.5em;
  }
  @media (min-width: 577px) {
    flex: 0 0 50%;
    max-width: 50%;
    margin-right: 1.5em;
  }
  @media (min-width: 980px) {
    flex: 0 0 70%;
    max-width: 70%;
    margin-right: 1.5em;
  }
}

.box-only-input-card {
  flex: 0 0 86.66%;
  max-width: 86.66%;
  margin-bottom: 0.5em;
}

.box-only-product-detail {
  @media (max-width: 979px) {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 1em;
  }
  @media (min-width: 980px) {
    flex: 0 0 41.66%;
    max-width: 41.66%;
    margin-right: 1.5em;
  }
}

.ibf-starting-price-mobile {
  @media (min-width: 980px) {
    display: none;
  }
}

.ibf-starting-price-desktop {
  @media (max-width: 979px) {
    display: none;
  }
}

.ibf-product-name {
  @media (min-width: 980px) {
    width: 85%;
  }
  @media (min-width: 480px) and (max-width: 979px) {
    width: 90%;
    margin-right: 20px;
  }
  &-wrapper {
    @media (min-width: 480px) {
      width: 100%;
    }
  }
}
