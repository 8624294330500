.ibf-nav-shadow {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
}
//search bar
.ibf-rounded-left {
  border-radius: 21px 0 0 21px !important;
}
.ibf-rounded-right {
  border-radius: 0 21px 21px 0 !important;
  border-top: solid 1px #e7e2df !important;
  border-right: solid 1px #e7e2df !important;
  border-bottom: solid 1px #e7e2df !important;
}

//search-bar size

@media (max-width: 980px) {
  .ibf-search-size {
    width: 100% !important;
  }
  .ibf-nav-sm-shadow {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
  }
}

//width of search-bar
@media (min-width: 981px) {
  .ibf-search-width {
    min-width: 60%;
  }
}
@media (min-width: 1300px) {
  .ibf-search-width {
    min-width: 70%;
  }
}

.ibf-nav {
  &-second {
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
      display: flex;
    }
  }
  &-mobile-menu {
    @media (max-width: 979px) {
      display: inline;
    }
    @media (min-width: 980px) {
      display: none;
    }
  }
  &-mobile-cart {
    @media (max-width: 767px) {
      display: flex;
    }
    @media (min-width: 768px) {
      display: inline;
    }
    @media (min-width: 980px) {
      display: none;
    }
  }
  &-desktop-cart {
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
      display: inline;
    }
  }
  &-block {
    @media (max-width: 979px) {
      display: block !important;
    }
  }
  &-sticky {
    position: sticky;
    top: 0;
    background: white;
  }
  &-color {
    &-container {
      border: 1px solid black;
      width: 50px;
      height: 20px;
    }
  }
}

.ibf-nav-menu {
  border: 1px solid rgb(231, 226, 223);
  border-radius: 22px !important;
  font-weight: bold;
  min-width: 80px;
  color: $ibf-color-gray;
}

.ibf-notification {
  &-cart {
    background: #e83293;
    top: 12px;
    right: 5px;
    border: 1px solid #f8f5f3;
    min-width: 26px;
    border-radius: 50%;
    position: absolute;
    color: #ffffff;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
  }

  &-filter {
    color: #ffffff;
    background: #23a2e4;
    border-radius: 50%;
    border: 1px solid #f8f5f3;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    min-width: 26px;
    position: absolute;
    right: 0;
    bottom: 15px;
  }

  &-filter-mobile {
    color: #ffffff;
    background: #23a2e4;
    border-radius: 50%;
    border: 1px solid #f8f5f3;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 24px;
    min-width: 26px;
    margin-left: 10px;
    top: -1px;
    text-align: center !important;
  }
}

.ibf-toggle {
  @media (max-width: 979px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: 980px) {
    display: inline;
  }
}

// header

.ibf-header {
  &-background {
    background-color: #e83293;
  }
  &-text {
    color: white;
  }

  &-arrow-green:after {
    right: 0;
    content: '';
    position: absolute;
    width: 30px;
    height: 60px;
    margin-top: 10px;
    margin-right: 88px;
    background-color: green;
    transform: rotate(45deg);
    z-index: -1;
    @media (max-width: 767px) {
      margin-right: 34px;
    }
  }

  &-arrow-orange:after {
    right: 0;
    content: '';
    position: absolute;
    width: 30px;
    height: 60px;
    margin-top: 10px;
    margin-right: 88px;
    background-color: orange;
    transform: rotate(45deg);
    z-index: -1;
    @media (max-width: 767px) {
      margin-right: 34px;
    }
  }
}

.ibf-nav-search {
  &-desktop {
    @media (max-width: 979px) {
      display: none;
    }
  }
  &-mobile {
    @media (min-width: 980px) {
      display: none;
    }
  }
}

.ibf-guest-header {
  &-text {
    color: white;
    font-weight: bolder;
  }
}

.ibf-address-icon {
  padding-left: 0.15em;
  padding-right: 0.55em;
}
