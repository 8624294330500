.signup-grid {
  display: grid;
  grid-template-columns: 21em 1fr;
  grid-column-gap: 30px;

  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
  }
}

.state-row {
  display: grid;
  align-items: end;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 6px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
}
