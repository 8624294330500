.ibf-display {
  &-filter {
    display: flex;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
    @media (max-width: 767px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    @media (min-width: 768px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: 980px) {
      display: none;
    }
    &-text {
      font-size: 16px;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  &-toggle {
    @media (max-width: 767px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: 768px) {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: 980px) {
      display: block;
      flex: none;
      //padding-left: 1rem;
      padding-right: 1rem;
      margin-right: 2em;
    }
  }

  &-toggle-modal {
    @media (max-width: 767px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: 768px) {
      flex: 0 0 50%;
      //max-width: 50%;
      display: flex;
      justify-content: center;
    }
    @media (min-width: 980px) {
      display: block;
      flex: none;
      //padding-left: 1rem;
      //padding-right: 1rem;
      //margin-right: 2em;
    }
  }

  &-filter-options {
    @media (max-width: 768px) {
      width: 100%;
      justify-content: space-between;
    }
  }

  &-sort {
    padding-left: 1rem;
    padding-right: 1rem;
    @media (max-width: 767px) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    @media (min-width: 768px) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @media (min-width: 980px) {
      display: none;
    }
  }
  &-sort-desktop {
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
    }
  }
}
