.checkbox {
  background-color: #fff;
  display: inline-block;
  height: 20px;
  margin: 0 0.25em;
  width: 20px;
  border-radius: 4px;
  border: 1px solid #e7e2df;
  float: left;
}

.checkbox span {
  display: block;
  height: 28px;
  position: relative;
  width: 28px;
  padding: 0;
}

.checkbox span:after {
  transform: scaleX(-1) rotate(135deg);
  transform-origin: left top;
  border-right: 4px solid #fff;
  border-top: 4px solid #fff;
  content: '';
  display: block;
  height: 13px;
  left: 1px;
  position: absolute;
  top: 10px;
  width: 7px;
}

.checkbox input {
  display: none;
}

.checkbox input:checked + .primary:after {
  border-color: #2196f3;
}

@keyframes check {
  0% {
    height: 0;
    width: 0;
  }
  25% {
    height: 0;
    width: 10px;
  }
  50% {
    height: 20px;
    width: 10px;
  }
}
