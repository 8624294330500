.ibf-sort {
  &-show-window {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1026;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    overflow-x: hidden;
    transition: 0.3s;
  }
  &-hide-window {
    width: 100vw;
    height: 100vh;
    background-color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    transform: translate3d(-100vw, 0, 0);
  }
  &-polygon {
    text-align: center !important;
    clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 18% 49%);
    color: black;
    background-color: white;
    border: 1px solid #e7e2df;
    min-height: 28px;
    min-width: 180px;
    margin-left: -30px;
    padding-left: 2em;
    :hover {
      color: #40a9ff;
    }
  }
  &-polygon-text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
  }
  &-close-icon {
    border-radius: 50% !important;
    border: 1px solid black;
    padding: 5px 9px 5px 9px;
    cursor: pointer;
    background-color: white;
  }
}
