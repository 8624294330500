.ibf-contact-popover {
  width: 315px;
  z-index: 2;

  .ant-btn {
    width: 100% !important;
  }

  .ibf-contact-options {
    border: 1px solid #e7e2df;
    border-radius: 2em;
    height: 3em;
    display: flex !important;
    justify-content: center;
    align-items: center;
    color: gray;

    &:hover {
      color: #23a2e4;
      text-decoration: none;
    }
  }
}
.popover-modal {
  z-index: 2000;
}

.ibf-contact {
  &-text {
    font-size: 15px;
    color: #888888;
  }
}
