.toast-size {
  width: 25%;
}

@media (max-width: 580px) {
  .toast-size {
    width: 100%;
  }
}

.ibf-circle {
  &-icon {
    border: 1px solid #333333;
    color: #333333;
    font-weight: bold;
    padding: 2px 8px;
    cursor: pointer;
    background: white;
  }
}

.ibf-toast {
  &-cart {
    height: 0.3rem;
    transform: rotate(180deg);
  }
  &-add-to-cart {
    z-index: 1025;
    text-align: center;
    background-color: #e7e2df;
    font-size: 15px;
    position: fixed;
    transition: visibility 0s, opacity 0.2s linear;
    right: 15px;
    //top: 130px;
    min-height: 120px;
    width: 355px;
    border-radius: 4px;
  }
  &-visible {
    visibility: visible;
    opacity: 1;
  }
  &-not-visible {
    visibility: hidden;
    opacity: 0;
  }
}
