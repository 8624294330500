.btn {
  border-radius: 3px;
}

.ibf-btn {
  &-cart {
    background-color: white;
    min-width: 80px;

    align-items: center;
    justify-content: center;
    @media (max-width: 979px) {
      display: flex;
    }
    @media (min-width: 768px) {
      width: 12em;
    }
  }
  &-grey {
    background-color: $ibf-color-ligth-grey;
    border: solid 1px lightgray;
    border-radius: 8px;
    padding: 0.25rem;
    color: $azure;
  }

  &-ant {
    color: $ibf-color-gray;
    border: 1px solid #e7e2df;
    border-radius: 22px;
    line-height: 30px;
    width: 100% !important;
    font-size: 16px;
  }
  &-sort-mobile {
    @media (max-width: 979px) {
      display: inline;
    }
    @media (min-width: 980px) {
      display: none;
    }
  }
  &-sort-desktop {
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
      display: inline;
      border: none;
      height: 0;
    }
  }

  &-form {
    width: 62px;

    &-list {
      border-radius: 4px;
      width: 100px;
    }
  }

  &-no-available {
    @media (max-width: 578px) {
      display: block;
      width: 100%;
    }
  }

  &-done {
    width: 200px;
    @media (max-width: 990px) {
      width: 182px;
      height: 30px;
    }

    &-wrapper {
      @media (max-width: 990px) {
        position: absolute;
        left: 16px;
        top: -20px;
        margin-right: 16px;
      }
    }
  }

  &-checkout-box {
    height: 22px !important;
  }
}

.btn-purple {
  color: #fff;
  background-color: $purply;
  border-color: $purply;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #e82893;
  opacity: 0.5;
  display: none;
}
.ibf-btn-circle {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.border-radius-22 {
  border-radius: 22px;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  -o-border-radius: 22px;
}
