.ibf-search {
  &-grid {
    display: grid;
    grid-template-columns: auto 13em 10em;
    grid-template-areas: 'search-input search-address search-date';

    .search-input {
      grid-area: search-input;
    }

    .search-address {
      grid-area: search-address;
    }

    .search-date {
      grid-area: search-date;
    }

    @media (max-width: 998px) {
      border: 1px solid #f2f2f2;
      //grid-template-columns: 55% 1fr;
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'search-input search-input'
        'search-address search-date';
    }
  }

  &-btn-search {
    border: 1px solid #e7e2df;
    border-bottom: 0;
    border-top-left-radius: 3px !important;
    border-right: 0;
  }
  &-input {
    border-right: 0;
    border-left: 0;
    border-bottom: 0;
  }
  &-btn-clear {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
  &-second-row {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
  }
  &-date-input {
    border-left: 1px solid #e7e2df;
    width: 50% !important;
  }
}

.ibf-search-popover {
  &-container {
    display: flex;
  }
  &-image {
    width: 8%;
    text-align: center;
  }
  &-content {
    width: 92%;
    padding: 0 0.3em;
  }
  &-input {
    //@media (min-width: 980px) {
    display: flex;
    justify-content: space-between;
    //}
  }
  &-title {
    @media (max-width: 979px) {
      font-size: 1.3em;
      margin-bottom: 0.5em;
    }
    @media (min-width: 980px) {
      font-size: 19px;
    }
  }
  &-text {
    @media (max-width: 979px) {
      font-size: 1.2em;
    }
    @media (min-width: 980px) {
      font-size: 18px;
    }
  }
  &-dont-show {
    padding: 0.25em 0;
    @media (max-width: 979px) {
      font-size: 1.2em;
      padding-left: 1em;
    }
    @media (min-width: 980px) {
      font-size: 16px;
    }
  }
  &-hide-desktop {
    @media (max-width: 979px) {
      display: none;
    }
  }
}
