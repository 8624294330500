@import 'variables';
.bg-ibf-yellow {
  background-color: $ibf-color-yellow;
}

.bg-ibf-calculating {
  background-color: #f2f2f2;
}

.bg-ibf-soft {
  background-color: $light-pink;
}

.bg-list {
  background-color: #f6f9f58a;
}

.bg-ibf-light-gray {
  background-color: $ibf-color-ligth-grey;
}
.bg-ibf-cream {
  background-color: $ibf-color-cream;
}
.bg-ibf-soft-gray {
  background-color: #e8dde4;
}
.bg-ibf-light-green {
  color: $ibf-progress-green;
}
.bg-ibf-orange {
  background-color: #f19923;
}
