.ibf-progress-bar {
  background-color: $ibf-color-light-gray;
  height: 100%;
}

.ibf-progress {
  background-color: white;
  height: 46px;
}

.ibf-progress-bar-message {
  position: relative;
  bottom: 84px;
  //float: right;
  //right: 3rem;

  @media (max-width: 580px) {
    bottom: 60px;
    right: 1.5rem;
  }
}
