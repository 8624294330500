.event {
  &-edit-row {
    display: grid;
    grid-template-columns: 18em 8em 1fr;
    padding: 0 9px;
    align-items: center;
    @media (max-width: 998px) {
      grid-template-columns: 14em 1fr;
      margin-bottom: 10px;
      &-description {
        grid-column: 1 / 3;
      }
    }
  }
}
