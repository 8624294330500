.box-product {
  &-grid {
    display: grid;
    grid-template-columns: 1fr 15em;

    @media (max-width: 1199px) {
      grid-template-columns: 1fr;
    }
  }

  &-info {
    &-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      column-gap: 2px;

      @media (max-width: 1199px) {
        grid-template-columns: 1fr 8em;
      }
    }
  }
}
