.cart-dropdown {
  font-size: 1rem;
  line-height: 1.18;
  margin-right: 1.5rem;

  &__icon {
    font-size: 1.5rem;
    margin-right: 0.25rem;
    position: relative;
  }

  &__icon-total {
    position: absolute;
    top: -1px;
    right: -4px;
    background: pink;
    padding: 0 0.125rem;
    font-size: 0.625rem;
    line-height: 1.3;
    color: #eff6ff;
    border: solid 1px #eff6ff;
    text-align: center;
    min-width: 1rem;
  }
}

//scroll for popOver
.ibf-cart {
  &-items {
    height: 87vh;
    overflow: auto;
  }

  &-orderR {
    display: flex;
    justify-content: center;
    background-color: #adadad;
    margin-top: 10px;
  }

  &-header {
    display: grid;
    align-items: center;
    grid-column-gap: 15px;
    grid-template-columns: repeat(2, auto) 3em;
    grid-template-areas: 'ibf-cart-title ibf-cart-button ibf-close-button';

    @media (max-width: 998px) {
      grid-template-columns: repeat(2, auto);
      grid-row-gap: 10px;
      grid-template-areas:
        'ibf-cart-title ibf-close-button'
        'ibf-cart-button ibf-cart-button';
    }

    .ibf-cart-title {
      grid-area: ibf-cart-title;
    }

    .ibf-close-button {
      grid-area: ibf-close-button;
      justify-self: end;
    }

    .ibf-cart-button {
      grid-area: ibf-cart-button;
    }
  }

  &-box-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 998px) {
      grid-template-columns: 1fr;
    }
  }
}

.ibf-cart-popover {
  z-index: 3;

  .ant-popover-inner-content {
    background: #ffffce;
  }
}

@media (min-width: 768px) {
  .ibf-shopping-cart-width {
    width: 415px;
  }
}

@media (max-width: 767px) {
  .ibf-shopping-cart-width {
    width: 350px;
  }
}

.ibf-cart-items-popover {
  position: fixed !important;

  .ant-popover-inner-content {
    padding: 0;
  }
}
