@import 'variables';

.info-loading-card {
  background: $ibf-color-yellow;
  border-radius: 15px;
  @media (max-width: 1024px) {
    width: 90vw;
  }
}

.ibf-heart-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.close-button {
  border: 1px solid #333333;
  color: #333333;
  font-weight: bold;
  padding: 3px 7px;
  cursor: pointer;
  background: white;
}
