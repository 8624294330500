.ibf-filter {
  &-list-sticky {
    position: sticky;
    bottom: 0;
    z-index: 2;
    @media (max-width: 979px) {
      background-color: #f2f2f2;
    }
    @media (min-width: 980px) {
      background-color: white;
    }
  }

  &-polygon {
    clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 18% 49%);
    color: black;
    background-color: white;
    border: 1px solid #e7e2df;
    min-height: 28px;
    margin-left: -25px;
    cursor: pointer;
    @media (max-width: 979px) {
      min-width: 50%;
    }
    :hover {
      color: #40a9ff;
    }
  }

  &-polygon-text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    @media (max-width: 979px) {
      padding-left: 1em;
    }
  }

  &-close-icon {
    border: 1px solid black;
    padding: 5px 9px 5px 9px;
    cursor: pointer;
    background-color: white;
  }

  &-list-mobile-view {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1024;
    top: 0;
    left: 0;
    background-color: #f2f2f2;
    overflow-x: scroll;
    transition: 0.5s cubic-bezier(0, 0.52, 0, 1);
    transform: translate3d(0vw, 0, 0);
  }

  &-desktop-view {
    //hide filter list mobile
    @media (max-width: 979px) {
      width: 100vw;
      height: 100vh;
      position: fixed;
      z-index: 1024;
      top: 0;
      left: 0;
      background-color: #f2f2f2;
      overflow-x: scroll;
      transition: 0.5s cubic-bezier(0, 0.52, 0, 1);
      transform: translate3d(-100vw, 0, 0);
    }
  }

  &-list-sticky {
    position: sticky;
    top: 0;
    z-index: 1;
    @media (max-width: 979px) {
      background: #f2f2f2;
    }
    @media (min-width: 980px) {
      background-color: white;
    }
  }

  &-list-items {
    background-color: rgb(242, 242, 242);
    border: 0;
  }
}

.filter-list {
  &-less {
    height: 149px;
  }
  &-empty {
    height: 60vh;
    border: dashed 2px $ibf-color-gray;
  }
}

.list {
  &-item {
    font-size: 13px;
    @media (max-width: 768px) {
      font-size: 17px;
    }

    &:hover {
      background: #f5f5f5;
    }
  }
  &-flower-category {
    font-size: 16px;
    &-active {
      background-color: #d7d7d7;
    }
    &:hover {
      background-color: #d7d7d7;
    }
  }
}

.dropdown {
  &-wrapper {
    width: 98%;
    height: 65vh;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1500;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  &-items {
    &-wrapper {
      max-height: 90%;
    }
    &-width {
      width: 200px;
    }
  }

  &-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 500;
    height: 100vh;
    position: fixed;
    left: 0;
    width: 100%;
  }
}

.selected-item {
  position: absolute;
  max-width: 142px;
  background-color: #e83293;
  height: 6px;
  left: 65px;
}

.my-account-line {
  position: absolute;
  width: 103px;
  background-color: #e83293;
  height: 6px;
  right: 23px;
  margin-top: 11px !important;
  z-index: 1599;
}
