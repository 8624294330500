.ibf-post {
  &-container {
    background-color: white;
    border: 1px solid #cccccc;
    min-height: 260px;
    margin: 1em 0;
    padding: 1em;
    border-radius: 10px;
    @media (max-width: 979px) {
      width: 92%;
      margin: 1em auto;
      min-height: 260px;
    }
    @media (min-width: 980px) {
      height: 100%;
      margin-right: 1em;
      width: 22%;
    }
  }
  &-title {
    margin-bottom: 1em;
    @media (max-width: 979px) {
      font-size: 20px;
    }
    @media (min-width: 980px) {
      font-size: 20px;
      //padding: 1em 0;
    }
  }

  &-card-slider {
    @media (max-width: 979px) {
      display: flex;
      flex-wrap: wrap;
    }
    @media (min-width: 980px) {
    }
    justify-content: space-between;
  }

  &-card-container {
    border-radius: 10px;
    border: 1px solid #cccccc;
    margin-bottom: 0.5em;
    overflow: hidden;
    @media (max-width: 529px) {
      width: 47%;
      height: 155px;
    }
    @media (min-width: 530px) {
      width: 145px;
      height: 155px;
    }
    @media (min-width: 980px) {
      width: 100%;
      height: 230px;
      margin-bottom: 1em;
    }
  }
  &-card-image {
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 979px) {
      height: 95px;
    }
    @media (min-width: 980px) {
      height: 160px;
    }
  }

  &-image {
    width: 100%;
  }
  &-card-description {
    @media (max-width: 979px) {
      padding: 0.25em 0.5em;
      font-size: 15px;
    }
    @media (min-width: 980px) {
      padding: 0 1em;
      line-height: 20px;
    }
  }
}
