.ibf-product-list {
  &-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 240px);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    //justify-content: center;

    @media (max-width: 979px) {
      grid-template-columns: repeat(1, 1fr);
    }
    &-item {
      grid-column-start: 1;
      grid-column-end: -1;
    }
  }
  &-container {
    width: 100%;
    @media (min-width: 980px) {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
  }
  &-results {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    //margin-top: 0.5rem;
    padding: 0 1em;
  }
}
