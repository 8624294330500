@media (max-width: 999px) {
  .ibf-pg-img {
    //width: 380px;
    //height: 120px;
    width: 100%;
    height: 200px;
  }
}

@media (min-width: 1000px) {
  .ibf-pg-img {
    width: 380px;
    height: 120px;
  }
  .ibf-pg-flex {
    display: flex;
  }
}

.ibf-all-flowers {
  &-width {
    width: 70vw;
  }
}
