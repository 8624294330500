@media (max-width: 576px) {
  //button's width when is mobile (list)
  .ibf-submit-button-list {
    width: 45px !important;
    border-radius: 4px !important;
  }

  //button's width when is mobile (card)
  .ibf-submit-button-card {
    width: 70px !important;
    border-radius: 4px !important;
  }
}

@media (min-width: 768px) {
  .ibf-text-result {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 33px;
    line-height: 40px;
    color: $ibf-color-gray;
  }
  .ibf-stem-desktop {
    width: 6vw;
    overflow: auto;
  }
}

//Max width
@media (max-width: 768px) {
  .ibf-max-width {
    max-width: 20vw !important;
  }
  .ibf-stem-mobile {
    width: 20vw;
    overflow: auto;
  }
  .ibf-text-result-small {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    color: $ibf-color-gray;
  }
}

// border for list
.ibf-border {
  border: 1px solid #e7e2df;
  margin-top: -1px;
  @media (min-width: 980px) {
    border-radius: 5px;
  }

  &-gray {
    border: 1px solid gray;
  }

  &-pink {
    border: 1px solid $ibf-color;
  }
}

// may be deleted--
.ibf-text-card {
  font-family: Lato;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  //color: black;
}

//text for the NavBar
.ibf-text-navbar {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $ibf-color-gray;
}

//text for the NavBar
.ibf-text-navbar-modal {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  //line-height: 24px;
  @media (max-width: 768px) {
    font-size: 12px;
  }
}

//delete Outline button
.ibf-no-outline {
  outline: none !important;
}

//text for result component
.ibf-link {
  color: $azure;
  font-weight: bold;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}

.ibf-text-detail-modal {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 33px;
  line-height: 40px;
  color: $ibf-color-gray;
}

//list form
.ibf-text-info {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  text-decoration: underline;
  line-height: 18px;
  color: #282c30;
}

//text for tip popOver
.ibf-text-tip {
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  color: $ibf-color-gray;
  @media (max-width: 978px) {
    font-size: 18px;
  }
  @media (min-width: 980px) {
    font-size: 22px;
  }

  &-start {
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: $ibf-color-gray;
    @media (max-width: 978px) {
      font-size: 16px;
    }
    @media (min-width: 980px) {
      font-size: 18px;
    }
  }

  &-actions {
    @media (max-width: 978px) {
      margin-bottom: 0.5em;
    }
  }

  &-bottom {
    @media (min-width: 980px) {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }

  &-dont-show {
    @media (max-width: 979px) {
      display: none;
    }
  }
}

.ibf-text-toast {
  &-success {
    color: green;
    font-style: normal;
    font-size: 19px;
    line-height: 23px;
    font-weight: bold;
  }
}

.ibf-text-gray {
  color: $ibf-color-gray;
}

.ibf-text-gray-cart {
  color: $ibf-color-gray-cart;
}

.ibf-text-red {
  color: #d8232d;
}

.ibf-text-purple {
  color: $purply;
}

.underline {
  text-decoration: underline;
}

.modal-title {
  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 425px) {
    font-size: 18px;
  }
}

.register-label {
  display: inline-block;
  color: #004d40;
  transition: all 0.5s ease;
  z-index: 0;
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 14px;
}

.normal-register-label {
  color: #004d40;
}

.register-label small {
  transition: all 1s ease;
  display: none;
}

.register-label-email {
  @extend .register-label;
  top: -23px;
  left: 4px;
}

input:focus ~ .register-label,
.not-empty.register-label,
input:not(:placeholder-shown) ~ .register-label {
  top: -22px;
  left: 0;
  font-weight: bolder;
}

input:focus ~ .register-label small,
.not-empty.register-label small,
input:valid ~ .register-label small {
  display: inline-block;
}

.ibf-popover-text {
  font-size: 16px;

  &-main {
    font-size: 18px;
  }
}

.ibf-font-12 {
  font-size: 12px;
}

.ibf-font-10 {
  font-size: 10px;
}
.ibf-font-11 {
  font-size: 11px;
}

.ibf-font-30 {
  font-size: 30px;
}

.ibf-font-20 {
  font-size: 20px;
}

.ibf-font-18 {
  font-size: 18px;
}

.ibf-font-14 {
  font-size: 14px;
}
.ibf-font-15 {
  font-size: 15px;
}
.ibf-font-17 {
  font-size: 1.063rem;
}

.ibf-font-16 {
  font-size: 16px;
}
.ibf-font-26 {
  font-size: 1.6rem;
}

.ibf-line-height-12 {
  line-height: 12px;
}

.ibf-switch-text-mobile {
  @media (max-width: 768px) {
    display: flex;
    margin-top: 3px;
  }
}

.ibf-product-text {
  font-size: 14px;
  font-family: Arial;
}

.clickable {
  cursor: pointer;
}
.ibf-text-pink {
  color: $ibf-color !important;
}

.text-black {
  color: black;
}
.ibf-text-line-height-1 {
  line-height: 1.2;
}
.text-ash-gray {
  color: #495057;
}
.ibf-text-line-height-2 {
  line-height: 2.1;
}
.ibf-text-line-through {
  text-decoration: line-through;
}
.text-gray-lighter {
  color: #bdbfc3;
}
.text-underline {
  text-decoration: underline;
}
