.size-icon {
  font-size: 1.563rem;
  margin-top: 3px;
  cursor: pointer;
  margin-left: 4px;
}
.title-modal-event {
  max-width: 37.5rem;
  margin-bottom: 1rem;
}
.content-piker {
  width: 80%;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 50px;
}

.button-color-picker {
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 50%;
  border: 1px solid black;
  position: relative;
  background: #f99b9c;
  outline: none;
  &-selected {
    border: 3px solid black;
  }
}
