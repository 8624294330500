$azure: #01a2e4;
$font-size-sm: 0.85rem;
$font-size: 1rem;
$font-size-md: 1.125rem;
$font-size-lg: 1.5rem;
$ibf-color: #e83293;
$ibf-bp-xl: 1200px;
$ibf-bp-lg: 992px;
$ibf-bp-md: 768px;
$ibf-bp-sm: 576px;
$ibf-bp: 576px;
$light-pink: #f4efed;
$purply: #9347b2;
$ibf-color-gray: #4a4a4a;
$ibf-color-ligth-grey: #f4efed;
$ibf-color-gray-cart: #979997;
$ibf-color-light-gray: #d7d7d7;
$ibf-color-yellow: #ffffce;
$ibf-progress-green: #93e069;
$ibf-color-cream: #fdf9cd;
$ibf-color-green: #09770a;
$theme-colors: (
  'primary': $azure
);

$font-family-base: 'Lato', sans-serif;
