.ibf-home {
  &-container {
    background-color: #f2f2f2;
    padding-top: 1em;
  }

  &-categories-post {
    @media (min-width: 980px) {
      display: flex;
      width: 98%;
      margin: 0 auto;
      justify-content: space-between;
    }
    @media (min-width: 1350px) {
      display: flex;
      max-width: 1500px;
      margin: 0 auto;
      justify-content: space-between;
    }
  }

  &-feedback {
    background-color: #f2f2f2;
    border: 1px solid #cccccc;
    min-height: 50px;
    padding: 1em;
    margin: 1.5em 0;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media (max-width: 979px) {
      width: 92%;
      margin: 2.5em auto;
      padding: 0.3em;
    }
    @media (min-width: 980px) {
      width: 98%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      height: 50px;
      margin-bottom: 1em;
    }
    @media (min-width: 1350px) {
      max-width: 1500px;
    }
    &-close {
      border: 1px solid black;
      padding: 3px 5px 3px 5px;
      cursor: pointer;
    }
  }
}
