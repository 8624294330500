.ibf-category {
  &-container {
    background-color: white;
    border: 1px solid #cccccc;
    min-height: 460px;
    margin: 1em 0;
    border-radius: 10px;
    @media (max-width: 979px) {
      width: 92%;
      margin: 0 auto;
    }
    @media (min-width: 980px) {
      min-height: 460px;
      padding: 1em;
      width: 78%;
    }
  }

  &-title {
    font-size: 20px;
    @media (max-width: 979px) {
      padding: 1em 1em 0 1em;
    }
    @media (min-width: 980px) {
      font-size: 20px;
      //padding: 1em;
    }
  }

  &-item-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1em;
    @media (max-width: 979px) {
      justify-content: space-between;
      padding: 1em;
    }
  }

  &-item {
    @media (max-width: 979px) {
      width: 100px;
    }
    @media (min-width: 980px) {
      width: 120px;
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 2em;
  }

  &-image-item {
    height: 70px;
    border-radius: 50%;
  }

  &-loading {
    @media (min-width: 980px) {
      height: 40vh;
    }
    width: 100%;
    justify-content: center;
    display: flex;
  }
}

.ibf-category-card {
  &-main-container {
    border-radius: 10px;
    border: 1px solid #cccccc;
    margin-bottom: 0.5em;
    overflow: hidden;
    cursor: pointer;
    @media (max-width: 529px) {
      width: 100%;
      height: 155px;
    }
    @media (min-width: 530px) {
      width: 300px;
      height: 155px;
    }
    @media (min-width: 980px) {
      height: 230px;
      margin-bottom: 1em;
      flex: 0 0 39.5%;
      max-width: 39.5%;
      margin-right: 0.5%;
    }
  }

  &-container {
    border-radius: 10px;
    border: 1px solid #cccccc;
    margin-bottom: 0.5em;
    overflow: hidden;
    cursor: pointer;

    @media (max-width: 529px) {
      width: 47%;
      height: 155px;
    }

    @media (min-width: 530px) {
      width: 145px;
      height: 155px;
    }
    @media (min-width: 980px) {
      height: 230px;
      margin-bottom: 1em;
      flex: 0 0 19.5%;
      max-width: 19.5%;
      margin-right: 0.5%;
    }
  }

  &-image-container {
    width: 100%;
    overflow: hidden;
    @media (max-width: 979px) {
      height: 95px;
    }
    @media (min-width: 980px) {
      height: 171px;
    }
  }

  &-image {
    width: 100%;
  }

  &-description {
    @media (max-width: 979px) {
      padding: 0.25em 0.5em;
      font-size: 15px;
    }
    @media (min-width: 980px) {
      padding: 0 1em;
    }
  }

  &-color-container {
    border-radius: 10px;
    border: 1px solid #cccccc;
    margin-bottom: 0.5em;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: 0.5%;
    max-width: 100%;
    margin: 0px;
    //margin-top: 0px;
    min-width: 100%;
    height: 70px;
    //margin-top: 20px;
    display: inline-flex;
  }

  &-color {
    -ms-transform: skew(50grad);
    -webkit-transform: skew(-25grad);
    position: relative;
    display: flex;
    padding: 0px;
    height: 100%;
    min-width: 104px;
    right: -25px;
    background-color: red;
    display: inline-flex;
    border-left: 3px solid white;
    cursor: pointer;

    @media (min-width: 1440px) {
      min-width: 132px;
    }
  }
}

.ibf-category-card-color span {
  -ms-transform: skew(25grad);
  -webkit-transform: skew(25grad);
  padding-left: 10px;
  padding-top: 20px;

  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  font-weight: 400;
}
