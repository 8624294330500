.ibf-so {
  &-container {
    background-color: white;
    border: 1px solid #cccccc;
    min-height: 100px;
    padding: 1em;
    margin: 1.5em 0;
    display: flex;
    align-items: center;
    border-radius: 10px;

    @media (max-width: 979px) {
      width: 92%;
      margin: 0.5em auto;
    }
    @media (min-width: 980px) {
      width: 98%;
      margin: 0 auto;
      display: flex;
      height: 100px;
      margin-bottom: 1em;
    }
    @media (min-width: 1350px) {
      max-width: 1500px;
      margin: 0 auto;
      display: flex;
      height: 100px;
      margin-bottom: 1em;
    }
  }

  &-redirection {
    margin: 0 auto;
    @media (max-width: 979px) {
      width: 100%;
    }
    @media (min-width: 980px) {
      width: 50%;
    }
    .ant-btn {
      width: 100%;
      border-radius: 20px;
      background: #219acc;
      opacity: 1;
      color: white;
      height: 35px;
      font-size: 20px;
      @media (min-width: 980px) {
        height: 40px;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }
}
