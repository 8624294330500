@import 'variables';

.main-text {
  color: #777;
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.modal-size {
  overflow: auto;
  max-height: 70vh;
}

@media (min-width: 768px) {
  .ibf-delivery-date-modal {
    width: 50vw;
  }
}

.address-modal-list {
  border: 1px solid rgba(0, 0, 0, 0.125);
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  cursor: pointer;
  margin-bottom: -1px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.ibf-modal {
  &-delivery-schedule {
    &-size {
      max-width: 85vw;
    }
    &-height {
      height: 80vh;
      max-height: 80vh;
      overflow: auto;
    }
  }

  &-confirmation-modal {
    &-height {
      max-height: 70vh;
      overflow: auto;
    }
  }

  &-close-button {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 21px;
    cursor: pointer;
  }
  &-confirmation-title {
    font-weight: 700;
    font-size: 32px;
    @media (max-width: 979px) {
      font-size: 20px;
    }
  }
  &-body-confirmation {
    margin: 0 auto;
    width: 80%;
    @media (min-width: 980px) {
      width: 83%;
    }
  }

  &-confirmation-body {
    font-weight: 400;
    font-size: 18px;
    padding: 0 15px;
    @media (max-width: 980px) {
      font-size: 14px;
    }
  }
  &-confirmation-footer {
    justify-content: start;
    padding: 0 3em 2em 3em;
    @media (max-width: 979px) {
      display: block;
    }
    @media (min-width: 980px) {
      width: 100%;
    }
  }
  &-confirmation-button {
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 979px) {
      margin-bottom: 1em;
    }
    @media (min-width: 980px) {
      width: 90%;
      margin: 0 auto 1em auto;
    }
    .ant-btn {
      width: 100%;
      height: 2.5em;
      border-radius: 5px;
      border: 1px solid #219acc;
      color: #219acc;
      font-weight: bolder;
      font-size: 20px;
    }
  }
  &-confirmation-button-2 {
    font-weight: 400;
    font-size: 20px;

    @media (max-width: 979px) {
      margin-bottom: 1em;
    }
    @media (min-width: 980px) {
      width: 90%;
      margin: 0 auto 1em auto;
    }
    .ant-btn {
      width: 100%;
      height: 2.5em;
      color: white;
      font-weight: bolder;
      border-radius: 5px;
      border: 1px solid #219acc;
      background-color: #219acc;
      font-size: 20px;
    }
  }
  &-confirmation-size {
    max-width: 669px;
  }

  &-company-size {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
  }
}

.media-body {
  flex: 1;
}

.information-percentage-container {
  border: 2px solid $purply;
  display: inline-block;
  width: 80px;
  height: 30px;
}

.information-percentage-container-loader {
  position: relative;
  height: 100%;
  background: $purply;
}

.percentage-text {
  position: relative;
  top: 3px;
  text-transform: uppercase;
  color: $purply;
  font-size: 0.9rem;
  margin-left: 10px;
}

.percentage-text:before {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  color: white;
}

.manager-separator {
  width: 100%;
  text-align: center;
  position: relative;
  font-size: 12px;
}

.manager-separator span {
  background-color: #fff;
  width: auto;
  display: inline-block;
  z-index: 3;
  position: relative;
  margin: 0;
}

.manager-card {
  height: 111px;
}

.primary-text-color {
  color: $ibf-color;
}

.ibf-login-modal {
  &-main {
    .modal-content {
      background-color: transparent;
      border: none;
    }
  }
  &-top-info {
    padding: 1em;
    background-color: #e0368e;
    color: white;
  }
  &-content {
    background-color: white;
    padding-top: 3em;
  }
  &-home {
    overflow: hidden;
    border-color: #e0368e;
    border-radius: 10px;
  }
  &-home-title {
    font-weight: bolder;
    text-align: center;
    overflow-wrap: break-word;
    @media (max-width: 979px) {
      font-size: 22px;
    }
    @media (min-width: 980px) {
      font-size: 30px;
    }
  }
  &-home-description {
    font-size: 20px;
    text-align: center;
    margin-bottom: 1em;
  }
  &-home-body {
    background-color: white;
    width: 80%;
    margin: 0 auto;
  }
  &-home-input {
    font-size: 18px;
  }
  &-password-input {
    margin-bottom: 1em;
    .ant-btn {
      height: 100%;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  &-continue-btn {
    margin-bottom: 1.5em;
    .ant-btn {
      width: 100%;
      background-color: #169bd5;
      color: white;
      font-weight: bold;
      border-radius: 135px;
      border: none;
      font-size: 1.1em;
      height: 2.1em;
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &-hr {
    margin-top: 2em;
    margin-bottom: 1.5em;
    @media (min-width: 980px) {
      padding: 0 3em;
    }
  }

  &-bottom-msg {
    cursor: pointer;
    font-weight: bolder;
    font-size: 13px;
    color: $azure;
  }

  &-bottom-text {
    font-weight: bolder;
    font-size: 13px;
  }

  &-bottom-img {
    min-height: 80px;
    background-color: white;
    padding: 1em 0;
    @media (min-width: 980px) {
      padding: 1em;
    }
  }

  &-invalid-input {
    font-size: 14px;
    margin-bottom: 1em;
  }

  &-forgot-msg {
    font-size: 16px;
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1em;
  }
}

.ibf-guest-modal {
  &-home {
    overflow: hidden;
    border-color: #e0368e;
    //border-radius: 10px;
    background-color: #e0368e;
    color: white;
  }
  &-home-title {
    font-weight: bolder;
    text-align: center;
    overflow-wrap: break-word;
    padding-top: 1em;
    @media (max-width: 979px) {
      font-size: 22px;
    }
    @media (min-width: 980px) {
      font-size: 30px;
    }
  }

  &-home-body {
    width: 80%;
    margin: 0 auto;
  }
  &-home-description {
    font-size: 20px;
    text-align: center;
    margin-bottom: 1em;
    padding: 0 2em;
    @media (max-width: 979px) {
      line-height: 22px;
    }
  }
  &-back-button {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 1.5em;
  }
}

.ibf-forgot-password-modal {
  &-home {
    overflow: hidden;
    border-color: #e0368e;
    border-radius: 10px;
    background-color: white;
    color: black;
  }
}

.ibf-greeting-modal {
  &-container {
    text-align: center;
    border-radius: 8px;
    background-color: white;
    overflow: hidden;
  }

  &-image {
    margin-bottom: 2em;
    @media (max-width: 767px) {
      height: 250px;
      background-repeat: no-repeat;
      background-size: 101%;
      background-image: url('https://sbxcloud.com/www/ibuyflowersdirect/images/welcome-mobile.png');
    }
    @media (min-width: 768px) {
      height: 200px;
      background-repeat: no-repeat;
      background-size: 101%;
      background-image: url('https://sbxcloud.com/www/ibuyflowersdirect/images/welcome-desktop.png');
    }
    @media (min-width: 980px) {
      height: 270px;
    }
  }

  &-title {
    @media (max-width: 979px) {
      font-size: 20px;
    }
    @media (min-width: 980px) {
      font-size: 22px;
    }
  }

  &-description {
    padding-bottom: 1em;
    @media (max-width: 979px) {
      font-size: 19px;
      width: 90%;
      margin: 0 auto;
    }
    @media (min-width: 980px) {
      font-size: 22px;
      width: 70%;
      margin: 0 auto;
    }
  }

  &-btn {
    @media (max-width: 979px) {
      width: 60%;
      margin: 0 auto;
    }
    @media (min-width: 980px) {
      width: 60%;
      margin: 0 auto;
    }
    .ant-btn {
      width: 100%;
      background-color: #169bd5;
      color: white;
      font-weight: bold;
      border-radius: 135px;
      border: none;
      font-size: 1.1em;
      height: 2.1em;
      align-items: center;
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
    }
  }

  &-dont-show {
    margin-bottom: 2em;
  }

  &-line {
    @media (min-width: 980px) {
      width: 70%;
      margin: 0 auto;
    }
  }

  &-footer-msg {
    color: #4a4a4a;
  }
}

.ibf-modal-border {
  border-radius: 8px;
  .modal-content {
    background-color: transparent;
    border: 0;
  }
}

.form-register {
  max-width: 600px;
  padding: 2rem 6rem;
}

.ibf-htu {
  &-container {
    border-radius: 10px;
  }
  &-video {
    margin: 1em auto;
    @media (max-width: 979px) {
      height: 15em;
      width: 90%;
    }
    @media (min-width: 980px) {
      height: 25em;
      width: 80%;
    }
  }
  &-instructions {
    margin: 1em auto;
    @media (max-width: 979px) {
      width: 90%;
    }
    @media (min-width: 980px) {
      width: 80%;
      font-size: 18px;
    }
  }
}

.ibf-learn-about {
  &-main {
    .modal-content {
      background-color: transparent;
      border: none;
    }
  }
  &-container {
    overflow: hidden;
    background-color: #e0368e;
    border-radius: 10px;
    color: white;
    padding: 1em;
  }
  &-title {
    font-weight: bolder;
    text-align: center;
    font-size: 29px;
  }
  &-description {
    font-size: 20px;
    text-align: center;
    padding: 0 1em;
    margin-bottom: 0.5em;
  }
  &-icons-container {
    text-align: center;
    font-size: 15px;
    margin-bottom: 1em;
    @media (min-width: 980px) {
      margin-bottom: 0.5em;
    }
  }
  &-icons {
    @media (min-width: 980px) {
      display: flex;
      justify-content: center;
      margin-bottom: 0.5em;
    }
  }
  &-video-container {
    width: 90%;
    margin: 0 auto 0.5em auto;
  }
  &-btn {
    width: 90%;
    margin: 0 auto 1em auto;
    .ant-btn {
      width: 100%;
      text-align: center;
      background-color: #169bd5;
      color: white;
      font-weight: bold;
      border-radius: 135px;
      border: none;
      font-size: 1.1em;
      height: 2.1em;
    }
  }
}

.ibf-shopping-modal {
  &-container {
    height: 100%;
    overflow-y: hidden;
    @media (min-width: 980px) {
    }
  }
  &-header {
    padding: 1em;
  }
  &-title {
    font-size: 24px;
    font-weight: bold;
    @media (max-width: 979px) {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 1em;
    }
  }
  &-contact {
    width: 30%;
    padding: 0 0.5em;
  }
  &-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-search {
    padding: 0 1em;
  }
}

.information-percentage-container {
  border: 2px solid #72b87c;
  display: inline-block;
  width: 122px;
  height: 30px;
}

.information-percentage-container-loader {
  position: relative;
  height: 100%;
  background: #72b87c;
}

.percentage-text {
  position: relative;
  top: 3px;
  color: black;
  font-size: 0.9rem;
  margin-left: 10px;
}

.percentage-text:before {
  content: attr(data-content);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  color: black;
}

.ibf-cellphone-modal {
  max-width: 600px !important;
  width: 550px;
  @media (max-width: 767px) {
    width: auto;
  }
}

.ibf-signin-modal {
  input:not(:placeholder-shown) ~ .register-label {
    color: white;
  }
}
