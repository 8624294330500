.ibf-footer {
  &-container {
    position: relative;
    left: 0;
    bottom: 0;
    text-align: center;
    line-height: 5em;
    @media (max-width: 979px) {
      padding-bottom: 3em;
    }
    @media (min-width: 980px) {
      justify-content: space-between;
      margin: 0 auto;
      width: 1000px;
      display: flex;
    }
  }
  &-redirection-place {
    display: flex;
  }
  &-element-container {
    @media (max-width: 979px) {
      width: 50%;
      text-align: left;
      padding: 0 1em;
    }
  }
  &-element {
    @media (max-width: 979px) {
      display: block;
      line-height: 3em;
    }
    margin-right: 0.5em;
    cursor: pointer;
  }
}
