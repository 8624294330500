.ibf-menu {
  &-sticky {
    background: white;
    position: sticky;
    top: 0;
    z-index: 2;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1) !important;
    padding: 1.5rem 1.5rem;
  }
  &-show-main-window {
    width: 85vw;
    height: 100vh;
    position: fixed;
    z-index: 1024;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    transition: 0.3s;
  }
  &-hide-main-window {
    width: 100vw;
    height: 100vh;
    background-color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    transform: translate3d(-100vw, 0, 0);
  }
}

.ibf-menu {
  &-show-window {
    width: 85vw;
    height: 80vh;
    position: fixed;
    z-index: 1024;
    top: 0;
    left: 0;
    background-color: white;
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s;
  }
  &-hide-window {
    width: 100vw;
    height: 100vh;

    background-color: #f2f2f2;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
    overflow: scroll;
    z-index: 1000;
    transform: translate3d(85%, 0, 0);
  }
}

.ant-dropdown-menu-submenu-placement-rightTop {
  top: 160px !important;
}

.ant-dropdown-menu {
  box-shadow: none !important;
}

.ant-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
}

.ibf-transition-slider {
  transform: translateX(0);
}
