.text-done {
  max-width: 28.125rem;
}
.content-button-show {
  padding-bottom: 3.2rem;
}
.button-show {
  margin-top: 1rem;
  width: 12.5rem;
  height: 2em;
  color: white;
  font-weight: bolder;
  border-radius: 5px;
  border: 1px solid #219acc;
  background-color: #219acc;
  font-size: 20px;
}
