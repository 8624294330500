.ibf-hi {
  &-container {
    background-repeat: no-repeat;
    border: 1px solid #bebebe;
    border-radius: 10px;
    opacity: 1;
    width: 1334px;
    overflow: hidden;
    padding: 0.5em;
    height: 250px;
    margin: 0 auto 2.5em auto;
    @media (max-width: 979px) {
      background-size: 100%;
      max-height: 240px;
      width: 92%;
      background-image: url('https://sbxcloud.com/www/ibuyflowers/prod/Assets/images/FallSeason-Mobile.png');
    }
    @media (min-width: 980px) {
      background-size: 120%;
      height: 215px;
      width: 98%;
    }
    @media (min-width: 1350px) {
      background-size: 100%;
      height: 250px;
      max-width: 1500px;
    }
    background-image: url('https://sbxcloud.com/www/ibuyflowers/prod/Assets/images/FallSeason-desktop.png');
  }

  &-container.bg-white {
    background-image: none;
  }

  &-contact-mobile {
    text-align: right;
    @media (max-width: 979px) {
    }
    @media (min-width: 980px) {
      display: none;
    }
  }

  &-contact-desktop {
    text-align: right;
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
      padding: 0 1em 0.5em 1em;
    }
  }

  &-description {
    @media (max-width: 979px) {
      font-size: 30px;
      text-align: left;
      line-height: 30px;
      opacity: 1;
      padding-left: 0.25em;
    }
    @media (min-width: 980px) {
      text-align: center;
      font-size: 40px;
    }
  }

  &-title {
    font-weight: bolder;
    @media (max-width: 979px) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-main-title {
    @media (max-width: 979px) {
      padding-top: 0.5em;
    }
  }

  &-popover {
    color: transparent;
    display: flex;
    justify-content: center;
    @media (max-width: 979px) {
      height: 5em;
    }
    @media (max-width: 321px) {
      height: 1em;
    }
  }

  &-text {
  }
  &-title-btn {
    @media (max-width: 979px) {
      width: 80%;
      margin: 1em 0.5em;
    }
    @media (min-width: 980px) {
      width: 50%;
      margin: 0 auto;
    }
    .ant-btn {
      width: 100%;
      border: 2px solid $azure;
      height: 2.5em;
      border-radius: 130px;
      color: $azure;
      font-weight: bold;
      @media (min-width: 980px) {
        font-size: 18px;
      }
    }
  }
}

.ibf-contact {
  cursor: pointer;
  border-radius: 50%;
  @media (max-width: 979px) {
    width: 35px;
  }
  @media (min-width: 980px) {
    width: 50px;
  }
}
