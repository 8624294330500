.carousel {
  &-button {
    height: 100px;
    width: 32px;
    color: white;
    background: rgba(0, 0, 0, 0.6);

    &:hover {
      color: white;
    }
  }

  &-arrow {
    font-size: 20px;
  }
}

.week-list {
  display: grid;
  grid-template-columns: repeat(13, 40px);
  column-gap: 5px;
  row-gap: 5px;
  grid-auto-rows: 40px;

  @media (max-width: 1264px) and (min-width: 992px) {
    grid-template-columns: repeat(11, 36px);
  }

  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(9, 37px);
  }
  @media only screen and (max-width: 376px) {
    grid-template-columns: repeat(8, 37px);
  }
}

.growers-flag {
  width: 16%;

  @media (max-width: 600px) {
    width: 33%;
  }
}

.product-detail {
  &-title {
    font-family: ArialMT, Arial;
    font-size: 24px;
    @media (max-width: 1200px) and (min-width: 769px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
    color: rgb(153, 153, 153);
  }
  &-name {
    font-size: 24px;
    @media (max-width: 1200px) and (min-width: 769px) {
      font-size: 20px;
    }
    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &-subtext {
    @extend .product-detail-title;
    color: black;
  }

  &-back-button {
    width: 33px;
    height: 33px;
    @media (max-width: 768px) {
      width: 26px;
      height: 26px;
    }
  }
}
