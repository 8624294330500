.ibf-menu {
  &-list-position {
    @media (max-width: 979px) {
      margin-left: -20px;
    }
    @media (min-width: 980px) {
      margin-left: -55px;
    }
  }
}
