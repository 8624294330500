.form-control {
  //box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e7e2df;
  background: #ffffff;
  border-radius: 3px;
}

.input-group-addon {
  border: 1px solid transparent;
  font-size: 0.875rem;
}

.group-input-append {
  input {
    border-right: 0;
  }

  .input-group-append {
    border: 1px solid #ced4da;

    i {
      line-height: 17px;
    }
  }
}

.group-input-prepend {
  input {
    border-left: 0;
  }

  .input-group-append {
    border: 1px solid #ced4da;
  }
}

.input-group-text {
  background-color: white;
}

.landing-form {
  &__wrapper {
    border-radius: 4px;
    border: solid 1px #cccccc;
    overflow: hidden;
  }

  .clearfix {
    border-bottom: solid 1px #cccccc;
  }

  .clearfix:last-child {
    border-bottom: none;
  }

  .clearfix > div {
    border-right: 1px solid #cccccc;
  }

  .clearfix > div:last-child {
    border: none;
  }

  .form-control,
  .input-group-addon {
    border: 1px solid transparent;
    font-size: $font-size-sm;
  }

  .form-control {
    border-bottom: 1px solid transparent;
    box-shadow: none !important;
    padding-right: 0.3125rem;
  }

  .input-group-addon {
    background: transparent;
  }
}

.file-select {
  position: relative;
  text-decoration: underline;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}

.ibf-input-search {
  border: solid 1px #e7e2df;
  background: #ffffff;
  border-radius: 3px;
}

//calendar

.ibf-calendar {
  .DayPicker-wrapper {
    border: 1px solid #e7e2df;
    border-bottom: 0;
  }

  &-border {
    border-left: 1px solid #e7e2df;
    border-right: 1px solid #e7e2df;
    margin-top: -10px;
    width: 100%;
  }

  &-wrapper {
    overflow: auto;
    max-height: 80vh;
  }

  &-border-top {
    border-left: 1px solid #e7e2df;
    border-right: 1px solid #e7e2df;
    border-top: 1px solid #e7e2df;
  }

  &-tip {
    background: #ffffcd;
    border: 1px solid #e7e2df;
    border-top: 0;
    padding: 0.5rem !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    -ms-flex-align: center !important;
  }
  &-availability {
    display: flex;
    justify-content: space-around;
    font-size: 0.9em;
    @media (max-width: 979px) {
      justify-content: space-between;
    }
  }

  &-multiselect-message {
    border: 2px solid #e7e2df;
    padding: 1rem !important;
    border-radius: 10px !important;
    flex-direction: column;
    display: flex;
    align-items: center;
  }

  &-multiselect-button {
    border-radius: 10px !important;
    width: 70%;
  }

  &-mobile {
    @media (max-width: 979px) {
      display: flex;
      align-items: center;
    }
    @media (min-width: 980px) {
      display: none;
    }
  }

  &-desktop {
    @media (max-width: 979px) {
      display: none;
    }
    @media (min-width: 980px) {
      display: inline;
    }
  }
}

.ibf-input {
  &-date-search {
    max-width: 170px;

    .DayPickerInput {
      width: 75%;
    }
  }

  &-date-width {
    max-width: 130px;

    .DayPickerInput {
      width: 76%;
    }
  }
}

//Styles for date picker input

.DayPicker-Day,
.DayPicker-WeekNumber {
  background-color: #8cd198;
}

.DayPicker-Day--disabled {
  background-color: #f4f4f4;
  font-weight: bold;
  color: #5d5d5d;
}

.DayPicker-Day--outside {
  background-color: white !important;
  background-image: none !important;
}

.DayPicker-Day {
  border-radius: 0 !important;
  border: 2px solid white;
  padding: 0.25em !important;
  overflow-x: hidden !important;
  height: 48px;
  width: 35px;
}

.DayPicker-Caption {
  text-align: center !important;
}

.DayPicker-NavButton--prev {
  @media (max-width: 979px) {
    right: 16em !important;
  }
  @media (min-width: 980px) {
    right: 37em !important;
  }
  background-image: url('https://sbxcloud.com/www/ibuyflowersdirect/images/icons/calendar/Chevron_left.png') !important;
  width: 1.8em !important;
}

.DayPicker-NavButton--next {
  background-image: url('https://sbxcloud.com/www/ibuyflowersdirect/images/icons/calendar/Chevron_right.png') !important;
  width: 1.8em !important;
  @media (max-width: 979px) {
    left: 18em !important;
  }
}

.DayPicker-Day--today {
  background-color: #f4f4f4;
  color: black !important;
  background-repeat: no-repeat;
  padding: 0 !important;
}

.DayPicker-Day-Months {
  border: none !important;
}

.DayPicker-Footer {
  position: relative;
  width: 0;
  height: 0;
  @media (max-width: 768px) {
    top: -21.5em;
    right: -14.5em;
  }
  @media (min-width: 769px) {
    top: -24em;
    right: -32em;
  }
}

.DayPicker-TodayButton {
  text-decoration: underline;
}

//input size mobile view
@media (max-width: 979px) {
  .DayPickerInput {
    width: 100%;
  }
}

.DayPicker-Day--selected {
  //background-image: url("https://sbxcloud.com/www/ibuyflowersdirect/images/icons/calendar/select.png");
  color: black !important;
  padding: 0 !important;
}

//
.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.input-register {
  //width: 50%;
  //@media (max-width: 980px) {
  //  width: 100%;
  //}
  position: relative;
  margin-top: 15px;
}

.calendar-arrival {
  .DayPicker-NavButton--prev {
    right: 11em !important;
  }
  .DayPicker-NavButton--next {
    @media (max-width: 979px) {
      left: 12em !important;
    }
  }

  .DayPicker-Day {
    background-color: white;
  }

  .DayPicker-Day--disabled {
    background-color: #f4f4f4 !important;
    font-weight: bold;
    color: #5d5d5d;
  }
}
.input-radio-transform {
  -webkit-appearance: radio;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
}
