@import '../../Styles/variables';

.dashboard {
  &-list {
    &-title {
      font-size: 1.25rem;
      font-weight: bold;
    }

    &-option {
      color: $ibf-color;
      font-size: 14px;
    }
  }

  &-button {
    background-color: $ibf-color;
    color: white;
  }

  &-balance-referral {
    font-weight: bold;
    font-size: 17.5px;
    color: $ibf-color;
  }

  input:focus ~ .register-label,
  .not-empty.register-label,
  input:valid ~ .register-label {
    top: -22px;
    left: 0;
    font-weight: bolder;
  }
}

.input-register-dashboard {
  position: relative;
  margin-top: 30px;
}

.register-label {
  display: inline-block;
  color: #004d40;
  transition: all 0.5s ease;
  position: absolute;
  top: 5px;
  left: 10px;
  font-size: 14px;
}

.register-label small {
  transition: all 1s ease;
  display: none;
}

input:focus ~ .register-label small,
.not-empty.register-label small,
input:valid ~ .register-label small {
  display: inline-block;
}
