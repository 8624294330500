.ibf-orders {
  &-container {
    width: 100%;
    max-width: 1300px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

.ibf-table-input-group {
  @media (min-width: 980px) {
    max-width: 14%;
    margin-right: 0.5rem;
  }
  @media (max-width: 979px) {
    margin-bottom: 0.5rem;
  }
}

.fedex {
  &-badge {
    display: block;
    background-color: #fdf9cd;
    color: #4d148c;
    font-size: 0.8125rem;
    padding: 0.375rem 0.625rem;
  }

  &-logo {
    vertical-align: baseline;
  }
}

.country-flag {
  width: 16px;
  margin-right: 5px;
  vertical-align: sub;
}

.ibf-order-detail {
  &-title {
    font-size: initial;
  }

  &-image {
    height: 150px;
    width: 150px;
    background: bisque;
  }

  &-box {
    display: flex;
    background: white;
    margin: 10px;
  }

  &-description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4px 38px 4px 12px;
    color: #777;
  }

  &-information {
    justify-content: space-between;
    display: flex;
  }

  small {
    font-size: 80%;
    font-weight: 400;
  }

  &-items {
    padding-left: 15px;
    padding-right: 15px;
    list-style: none;
  }

  h4 {
    font-size: 1.3rem;
  }

  &-delivery-status {
    background-color: #fdf9cd;
    padding-left: 15px;
    padding-right: 15px;
    color: #4d148c;
    margin: 0 -38px -4px -12px;
    display: flex;
    justify-content: space-between;
  }

  &-sidebar {
    background: #f4efed;
    margin: 10px;
  }
}

.sidebar-header {
  padding: 0.625rem 1.125rem;
}

.sidebar-detail {
  padding: 0.625rem 1.125rem;
  font-size: 1rem;
  color: #777;
  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  &-last {
    font-size: 1.2rem;
    padding: 0.625rem 1.125rem;
    color: #777;
  }

  &-title {
    margin-bottom: 0.25rem;
  }
}

.card-payment-method {
  background-color: #fff;
  padding: 0.5rem;

  &-icon {
    width: 28px;
    height: 22px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
  }
}
