.custom-step-desktop-popover {
  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    border-left-color: #ffffce !important;
    border-top-color: #ffffce !important;

    background-color: #ffffcc;
  }
}

.custom-validation-popover {
  z-index: 1026;

  @media (min-width: 980px) {
    width: 200px;
  }

  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    border-left-color: #ffffce !important;
    border-top-color: #ffffce !important;

    background-color: #ffffcc;
  }
}

.popover-overlay {
  background: rgba(0, 0, 0, 0.2);
  z-index: 1025;
  height: 100vh;
  position: fixed;
  left: 0;
  width: 100%;
}

.custom-step-title-popover {
  z-index: 1026;

  @media (min-width: 980px) {
    width: 350px;
  }

  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    display: none;
  }
}

.custom-popover-desktop {
  z-index: 1026;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffce;
  position: absolute;
  width: 350px;
  height: 50px;
  top: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;

  @media (max-width: 978px) {
    width: 250px;
  }
}

.ibf-popover {
  &-cart {
    z-index: 9999;
    padding: 0;

    @media (max-width: 998px) {
      padding-right: 10px;
    }

    .ant-popover-inner {
      background-color: transparent !important;
      box-shadow: none;
    }

    .ant-popover-inner-content {
      padding: 0;
    }

    .ant-popover-arrow {
      display: none;
    }
  }
}

.ibf-popover-yellow {
  z-index: 1026;

  @media (min-width: 980px) {
    width: 400px;
  }

  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    border-left-color: #ffffce !important;
    border-top-color: #ffffce !important;

    background-color: #ffffcc;
  }
}

.custom-step-mobile-popover {
  position: fixed !important;

  @media (max-width: 978px) {
    width: 250px;
    padding-left: 10px;
  }

  @media (min-width: 980px) {
    display: none;
  }

  .ant-popover-inner-content {
    background: #ffffce;
  }

  .ant-popover-arrow {
    border-top-color: #ffffce !important;
    border-left-color: #ffffce !important;
  }
}

.ibf-home-info-popover {
  z-index: 0;
  border-radius: 10px;

  @media (max-width: 979px) {
    width: 300px;
  }

  @media (min-width: 980px) {
    width: 370px;
  }

  .ant-popover-inner-content {
    background: #ffffcc;
    border-radius: 10px;
  }

  .ant-popover-inner {
    border-radius: 10px;
  }

  .ant-popover-arrow {
    border-top-color: #ffffcc !important;
    border-left-color: #ffffcc !important;
    //arrow bigger
    width: 25px;
    height: 25px;
    background-color: #ffffcc;
    top: 0 !important;
  }

  .ant-btn {
    display: flex;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    color: white;
    font-weight: bolder;
    border-radius: 140px;
    border-color: rgba(121, 121, 121, 1);
    background-color: #219acc;

    @media (min-width: 980px) {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.ibf-search-tip-popover {
  position: fixed !important;

  @media (max-width: 979px) {
    display: none;
  }

  @media (min-width: 980px) {
    width: 400px;
  }

  .ant-popover-inner-content {
    background: $ibf-color-yellow;
  }

  .ant-popover-arrow {
    border-bottom-color: $ibf-color-yellow !important;
    border-left-color: $ibf-color-yellow !important;
    //arrow bigger
    width: 25px;
    height: 25px;
    left: 0 !important;
    background-color: #ffffcc;
  }
}

.ibf-search-tip-popover-mobile {
  position: fixed !important;

  @media (max-width: 979px) {
    width: 348px;
  }

  @media (min-width: 980px) {
    display: none;
  }

  .ant-popover-inner-content {
    background: $ibf-color-yellow;
  }

  .ant-popover-arrow {
    border-top-color: $ibf-color-yellow !important;
    border-left-color: $ibf-color-yellow !important;
    //arrow bigger
    width: 25px;
    height: 25px;
    top: 0 !important;
    background-color: #ffffcc;
  }
}

.ibf-shopping-modal-popover-mobile {
  z-index: 2000;

  @media (max-width: 979px) {
    width: 350px;
  }

  @media (min-width: 980px) {
    width: 518px;
  }

  .ant-popover-inner-content {
    background: #ffffcc;
  }

  .ant-popover-arrow {
    border-bottom-color: #ffffcc !important;
    border-left-color: #ffffcc !important;
    border-right-color: #ffffcc !important;

    //arrow bigger
    width: 25px;
    height: 25px;
    background-color: #ffffcc;
    bottom: 0 !important;
  }

  .ant-btn {
    border-radius: 7px;
    width: 100%;
  }
}

.ibf-shopping-modal-popover-desktop {
  @extend .ibf-shopping-modal-popover-mobile;

  left: 939px !important;

  @media (max-width: 1600px) {
    left: 743px !important;
  }

  .ant-popover-arrow {
    border-bottom-color: #ffffcc !important;
    border-left-color: #ffffcc !important;
    border-right-color: #ffffcc !important;

    //arrow bigger
    left: 15% !important;
    width: 25px;
    height: 25px;
    background-color: #ffffcc;
    bottom: 0 !important;
  }
}

.ibf-popover-referral {
  z-index: 2000;

  .ant-popover-inner-content {
    background: #ffffcc;
    border: 1px solid black;
    width: 450px;
  }

  .ant-popover-arrow {
    display: none;
  }

  @media (max-width: 989px) {
    .ant-popover-inner-content {
      width: 350px;
    }
  }
}

.size-popover {
  width: 30%;
  @media (max-width: 600px) {
    width: 75%;
  }
}
