.ibf-referral {
  &-container {
    background-color: white;
    border: 1px solid #cccccc;
    //min-height: 230px;
    //padding: 2em;
    margin: 1em 0;
    border-radius: 10px;
    @media (max-width: 979px) {
      //padding: 1em;
      width: 92%;
      margin: 0.5em auto;
    }
    @media (min-width: 980px) {
      //max-width: 1000px;
      //padding: 2em;
      width: 98%;
      margin: 0 auto;
      display: flex;
      //height: 230px;
    }
    @media (min-width: 1350px) {
      max-width: 1500px;
    }
  }

  &-coupon {
    @media (max-width: 980px) {
      margin-bottom: 0.5em;
    }
    @media (min-width: 980px) {
      width: 60%;
      font-size: 16px;
    }

    .ant-btn {
      color: white;
      background-color: #219acc;
      border-radius: 24px;
      @media (min-width: 980px) {
        width: 70%;
        height: 40px;
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

  &-title {
    margin-bottom: 0.25em;
    @media (min-width: 980px) {
      font-size: 24px;
    }
  }

  &-description {
    margin-bottom: 1em;
    @media (min-width: 980px) {
      font-size: 16px;
    }
  }

  &-button-redirection {
    @media (max-width: 979px) {
      width: 100%;
      .ant-btn {
        width: 100%;
        height: 35px;
        font-size: 20px;
      }
    }
  }

  &-price {
    @media (min-width: 980px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40%;
    }
  }
  &-price-1 {
    @media (min-width: 980px) {
      font-size: 100px;
      line-height: 100px;
    }
  }

  &-price-description {
    @media (min-width: 980px) {
      font-size: 20px;
      display: block;
      text-align: center;
    }
  }

  &-coupon-price {
    @media (min-width: 980px) {
    }
  }

  &-wrapper {
    width: 90%;
    margin: 0.5rem;
    @media (min-width: 980px) {
      width: 50%;
      margin: 1rem;
    }
  }
}
