@import 'variables';

.suggested {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 2;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  &-title {
    border-bottom: 1px solid #c5c5c5;
    border-top: 1px solid #c5c5c5;

    display: inline-block;
    width: 100%;
    color: darken(darkgray, 40);
  }

  &-item:hover {
    background-color: rgba(0, 0, 0, 0.2);
    color: $azure;
    cursor: pointer;
  }

  // border: 1px solid black;
  border-bottom: 0;
}
